export default {
  "Search for you next machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for you next machine"])},
  "CONTACT US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTACT US"])},
  "Firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firstname*"])},
  "Lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastname*"])},
  "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email*"])},
  "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description*"])},
  "Send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
  "Buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
  "Sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell"])},
  "Pays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country*"])},
  "Adresse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adress*"])},
  "Enter an adress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter an adress"])},
  "Enter a country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a country"])},
  "Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
  "Opportunity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opportunity"])},
  "InputCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company*"])},
  "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "Search...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search..."])},
  "Subscribe to this newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe to this newsletter"])},
  "You are subscribed to the newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your newsletter subscription has been successfully registered"])},
  "You have already registered for the newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have already registered for the newsletter"])},
  "Please enter an email before clicking the button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter an email before clicking the button"])},
  "Please enter a valid email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid email"])},
  "FIND YOUR MACHINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FIND YOUR MACHINE"])},
  "Keyword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyword"])},
  "Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sector"])},
  "Choose a sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a sector"])},
  "SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEARCH"])},
  "SELL YOUR MACHINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SELL YOUR MACHINE"])},
  "Model Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model Name"])},
  "Brand Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand Name"])},
  "Year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
  "InputYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
  "SELL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SELL"])},
  "Subscribe to the newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe to the newsletter"])},
  "Email address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
  "Subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe"])},
  "Aucun résultat trouvé": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No result found"])},
  "After (Year)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After (Year)"])},
  "FILTERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FILTERS"])},
  "Brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand"])},
  "inputBrand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand"])},
  "Category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
  "Choose a category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a category"])},
  "Choose a brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a brand"])},
  "Specs :": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specs :"])},
  "Need more informations ?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need more informations ?"])},
  "Feel free to ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feel free to ask"])},
  "Type : ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type : "])},
  "Ref :": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ref :"])},
  "Brand :": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand :"])},
  "Year :": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year :"])},
  "MACHINES SELECTED FOR YOU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MACHINES SELECTED FOR YOU"])},
  "YOU WANT TO SELL YOUR MACHINE ?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YOU WANT TO SELL YOUR MACHINE ?"])},
  "We may be interested, please give us some informations.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We may be interested, please give us some informations."])},
  "About your machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About your machine"])},
  "Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model"])},
  "inputModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model"])},
  "Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
  "InputPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
  "About you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About you"])},
  "Learn about our history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn about our history"])},
  "TRADE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRADE"])},
  "Needless to say, this is buying and selling. In the context of our operations we have to buy entire factories or lots of equipment in advance.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Needless to say, this is buying and selling. In the context of our operations we have to buy entire factories or lots of equipment in advance."])},
  "This is a risk that we do not hesitate to take, in order to have a permanent stock on hand to offer to possible buyers.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is a risk that we do not hesitate to take, in order to have a permanent stock on hand to offer to possible buyers."])},
  "BROKERAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BROKERAGE"])},
  "In this case we are concerned with selling in your best interests, the equipment which you no longer need, in return for a commission fixed in advance.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this case we are concerned with selling in your best interests, the equipment which you no longer need, in return for a commission fixed in advance."])},
  "For this, we regularly publish (more than 35000 copies) a bulletin listing all the equipment available for sale, and distribute it world-wide, based on a file which is updated daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For this, we regularly publish (more than 35000 copies) a bulletin listing all the equipment available for sale, and distribute it world-wide, based on a file which is updated daily"])},
  "APPRAISALS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APPRAISALS"])},
  "Essential as much for the buyer as the seller. We insist on carrying this out in absolute confidentiality for both parties concerned.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essential as much for the buyer as the seller. We insist on carrying this out in absolute confidentiality for both parties concerned."])},
  "Our knowledge of the market and our experience in the textile field allow us to take everything into account when making an appraisal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our knowledge of the market and our experience in the textile field allow us to take everything into account when making an appraisal"])},
  "100 years of experience at your service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100 years of experience at your service"])},
  "JACQUART company, already handed down from father to son for four generations, is justly proud of its past. Their experience as mill owners gives them the necessary expertise to act as highly qualified intermediaries between companies wishing to sell some individual machines or even their entire factory, and those who wish to buy second-hand textile equipment. Their competence is well-known and recognised by all professionals in the textile industry (mill owners, weavers, dyers, finishers) and those in sales (trade unions, auctioneers, financial institutions...).": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JACQUART company, already handed down from father to son for four generations, is justly proud of its past. Their experience as mill owners gives them the necessary expertise to act as highly qualified intermediaries between companies wishing to sell some individual machines or even their entire factory, and those who wish to buy second-hand textile equipment. Their competence is well-known and recognised by all professionals in the textile industry (mill owners, weavers, dyers, finishers) and those in sales (trade unions, auctioneers, financial institutions...)."])},
  "Needless to say, this is buying and selling. In the context of our operations we have to buy entire factories or lots of equipment in advance. This is a risk that we do not hesitate to take, in order to have a permanent stock on hand to offer to possible buyers.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Needless to say, this is buying and selling. In the context of our operations we have to buy entire factories or lots of equipment in advance. This is a risk that we do not hesitate to take, in order to have a permanent stock on hand to offer to possible buyers."])},
  "In this case we are concerned with selling in your best interests, the equipment which you no longer need, in return for a commission fixed in advance. For this, we regularly publish (more than 35000 copies) a bulletin listing all the equipment available for sale, and distribute it world-wide, based on a file which is updated daily.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this case we are concerned with selling in your best interests, the equipment which you no longer need, in return for a commission fixed in advance. For this, we regularly publish (more than 35000 copies) a bulletin listing all the equipment available for sale, and distribute it world-wide, based on a file which is updated daily."])},
  "Essential as much for the buyer as the seller. We insist on carrying this out in absolute confidentiality for both parties concerned. Our knowledge of the market and our experience in the textile field allow us to take everything into account when making an appraisal.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essential as much for the buyer as the seller. We insist on carrying this out in absolute confidentiality for both parties concerned. Our knowledge of the market and our experience in the textile field allow us to take everything into account when making an appraisal."])},
  "Purchases - Sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchases - Sales"])},
  "Our team is always ready to inspect equipment for sale on site, wherever its location. Visitors from all over the world take an interest in our second-hand equipment. Our aims in serving our clients are many : Canvassing, going to see equipment to be sold on site, wherever its location, making appraisals, replying to demands, meeting potential buyers…then supervising dimdantling and reassemble of equipment, transport, customs formalities etc... This we do with great passion and always with the essential philosophy of selling in mind : of serving our customers to the maximum.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our team is always ready to inspect equipment for sale on site, wherever its location. Visitors from all over the world take an interest in our second-hand equipment. Our aims in serving our clients are many : Canvassing, going to see equipment to be sold on site, wherever its location, making appraisals, replying to demands, meeting potential buyers…then supervising dimdantling and reassemble of equipment, transport, customs formalities etc... This we do with great passion and always with the essential philosophy of selling in mind : of serving our customers to the maximum."])},
  "Implantation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Implantation"])},
  "Subscribe to our newsletter and receive new offers from our machine catalog. You can activate notifications on a specific category.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe to our newsletter and receive new offers from our machine catalog."])},
  "STAY TUNED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STAY TUNED"])},
  "Loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
  "ITALY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ITALY"])},
  "Make your choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make your choice"])},
  "Enter a machine model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a machine model"])},
  "Enter a machine brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a machine brand"])},
  "Enter a machine description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a machine description"])},
  "Enter the year of manufacture of the machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the year of manufacture of the machine"])},
  "Year of manufacture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year of manufacture"])},
  "Enter a machine price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a machine price"])},
  "Enter a firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a firstname"])},
  "Enter a lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a lastname"])},
  "Enter a email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a email"])},
  "Form send successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form send successfully"])},
  "Something went wrong, please try again later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong, please try again later"])},
  "Enter a valid email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a valid email"])},
  "Enter a description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a description"])},
  "* Required fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Required fields"])},
  "Opportunity page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opportunity page"])},
  "description_not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description not available"])},
  "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company name"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "Activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
  "subActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machines"])},
  "Subcategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub-category"])},
  "interested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'm interested"])},
  "auction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auction"])},
  "no-pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No pictures available"])},
  "all-fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in all fields"])},
  "send-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There has been an error and your request has not been registered!"])},
  "send-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request has been sent successfully!"])},
  "opportunities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opportunities"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
  "You-have-selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have selected: "])},
  "please-fill-all-fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in all required fields"])},
  "File": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])},
  "Select-activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select your activities"])}
}