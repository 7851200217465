import { createStore } from "vuex";
import axios from "axios";

// const locale = navigator.language.split('-')[0];
export default createStore({
  state: {
    machines: [],
    categories: [],
    sectors: [],
    navig: navigator.language.startsWith("fr") ? "fr" : "en",
  },
  mutations: {
    loadMachines(state, machines) {
      state.machines = machines;
    },
    loadCategories(state, categories) {
      state.categories = categories;
    },
    loadSectors(state, sectors) {
      state.sectors = sectors;
    },
    loadNavig(state, navig) {
      state.navig = navig;
    },
  },
  actions: {
    loadMachines({ commit }) {
      let navig = navigator.language.startsWith("fr") ? "fr" : "en";
      axios.get(`${process.env.VUE_APP_STRAPI_API_URL}/api/machine-fiches?sort=id%3Adesc&locale=${navig.split("-")[0]}&populate=*&pagination[pageSize]=4`).then((response) => {
        commit("loadMachines", response.data.data);
      });

      // const response = axios.get(`${process.env.VUE_APP_STRAPI_API_URL}/api/machines?populate=*`)
      //   this.machines = response.data.data
      //     commit("CARD_MACHINE", machinesItem)
      //   console.log(response.data.data[0].attributes.images.data);
    },

    // loadCategories({ commit }) {
    //   let navig = navigator.language.startsWith("fr") ? "fr" : "en";

    //   axios
    //     .get(`${process.env.VUE_APP_STRAPI_API_URL}/api/categories?locale=${navig.split("-")[0]}`)
    //     .then((response) => {
    //       commit("loadCategories", response.data.data);
    //     });
    // },

    // loadSectors({ commit }) {
    //   let navig = navigator.language.startsWith("fr") ? "fr" : "en";

    //   axios
    //     .get(
    //       `${process.env.VUE_APP_STRAPI_API_URL}/api/sectors?locale=${
    //         navig.split("-")[0]
    //       }&populate=categories`
    //     )
    //     .then((response) => {
    //       commit("loadSectors", response.data.data);
    //     });
    // },
  },
});
