<template>
  <div class="home container flex mx-auto flex-col">
    <Header />
    <!-- <Card /> -->
    <ButtonSearch />
    <NewsLetter />
    <CardStatic />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import ButtonSearch from "@/components/ButtonSearch";
import NewsLetter from "@/components/NewsLetter";
import CardStatic from "@/components/CardStatic";
// import Card from "@/components/Card";

export default {
  name: "Home",
  data() {
    return {
      machines: [],
    };
  },

  components: {
    Header,
    NewsLetter,
    CardStatic,
    ButtonSearch,
    // Card,
  },
};
</script>
