<template>
  <div>
    <Nav class="sticky z-10 pb-8" />
    <router-view class="" />
    <Footer class="" />
  </div>
</template>

<script>
import Nav from "@/components/Nav";
import Footer from "@/components/Footer";

export default {
  name: "App",
  components: {
    Nav,
    Footer,
  },
  created() {
    this.$store.dispatch("loadMachines"), this.$store.dispatch("loadCategories"), this.$store.dispatch("loadSectors");
  },
};
</script>

<style></style>
