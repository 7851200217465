<template>

  <div class="flex items-center justify-center">
    <form action="/buy" method="get" class="flex space-x-2">
      <input
        v-model="search"
        name="search"
        type="text"
        class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
        :placeholder="$t('Search...')"
      />
      
        <button
          type="submit"
          class="px-6 py-2 border-2 border-green-700 text-green-700 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
        >
          {{ $t("Search") }}
        </button>
     
    </form>
  </div>
</template>
<script>
// import axios from 'axios'

export default {
  data() {
    return {
      search : '',
    }
  },

}




</script>
