<template>
  <div class="px-10">
    <div
      class="flex justify-center items-center mx-auto flex-wrap md:max-w-4xl rounded bg-cardColor"
    >
      <div class="flex justify-center text-center flex-wrap px-10 py-5">
        <h4 class="bg-black text-white p-2 text-3xl rounded">{{ $t("STAY TUNED") }}</h4>
      </div>
      <div>
        <p class="flex justify-center items-center px-5">
          {{
            $t(
              "Subscribe to our newsletter and receive new offers from our machine catalog. You can activate notifications on a specific category."
            )
          }}
        </p>
      </div>
      <form action="/newsletter" method="get">
        <div class="flex justify-center items-center px-5">
          <input
            type="email"
            placeholder="Email :"
            name="email"
            v-model="email"
            class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
          />
        </div>
        <div class="flex justify-center items-center py-2">
          <button
            class="px-6 py-2 border-2 border-green-700 text-green-700 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
          >
            {{ $t("Subscribe to this newsletter") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
// import axios from "axios";

export default {
  name: "NewsLetter",

  data() {
    return {
      email: "",
    };
  },
  // methods: {
  //   async submitForm() {
  //     const mailFormat = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/;
  //     if (this.email.match(mailFormat)) {
  //       axios
  //         .post(`${process.env.VUE_APP_STRAPI_API_URL}/api/newsletters`, {
  //           data: { email: this.email },
  //         })
  //         .then((response) => {
  //           if (response.status == 200) {
  //             alert(this.$t("You are subscribed to the newsletter"));
  //             this.email = "";
  //             return true;
  //           }
  //           console.log(response.status);
  //         })
  //         .catch((error) => {
  //           if (error.response.status == 400) {
  //             if (
  //               error.response.data.error.message ==
  //               "This attribute must be unique"
  //             )
  //               alert(this.$t("You have already registered for the newsletter"));
  //           } else {
  //             console.log(error.message);
  //           }
  //           return false;
  //         });
  //       return true;
  //     } else if (this.email == "") {
  //       alert(this.$t("Please enter an email before clicking the button"));
  //       return false;
  //     } else {
  //       alert(this.$t("Please enter a valid email"));
  //       return false;
  //     }
  //   },
  // },
};
</script>
