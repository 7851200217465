<template>
  <footer class="mx-auto text-center bg-gray-100 rounded text-gray-600">
    <div class="px-6 pt-6">
      <!-- <div class="flex justify-center mb-6">
        <a href="https://fr-fr.facebook.com" target="_blank" type="button"
          class="rounded-full border-2 border-facebook text-facebook leading-normal uppercase hover:bg-green-900 hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-9 h-9 m-1">
          <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f" class="w-2 h-full mx-auto"
            role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
            <path fill="currentColor"
              d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z">
            </path>
          </svg>
        </a>
        <a href="https://fr.linkedin.com" target="_blank" type="button"
          class="rounded-full border-2 border-linkedin text-linkedin leading-normal uppercase hover:bg-green-900 hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-9 h-9 m-1">
          <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in" class="w-3 h-full mx-auto"
            role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path fill="currentColor"
              d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z">
            </path>
          </svg>
        </a>
      </div> -->

      <div>
        <form action="/newsletter" method="get">
          <div class="grid md:grid-cols-3 gird-cols-1 gap-4 justify-center items-center">
            <div class="md:ml-auto md:mb-6">
              <p class="">
                <strong>{{ $t("Subscribe to the newsletter") }}</strong>
              </p>
            </div>

            <div class="md:mb-6">
              <input
                type="email"
                name="email"
                id="email"
                v-model="email"
                class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
                :placeholder="$t('Email address')"
              />
            </div>

            <div class="md:mr-auto mb-6">
              <button class="inline-block px-6 py-2 border-2 border-green-700 text-green-700 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out">
                {{ $t("Subscribe") }}
              </button>
            </div>
          </div>
        </form>
      </div>

      <div class="flex justify-center lg:flex md:flex">
        <div class="mb-6">
          <h5 class="uppercase font-bold mb-2.5">Contact</h5>

          <ul class="list-none mb-0">
            <li>
              <a href="https://goo.gl/maps/ZzfwmwEVEXHrkm1L8" target="_blank" class="text-gray-600 hover:text-green-600 flex items-center justify-center mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" /></svg
                >33 Chemin de Cézanne, 13016 Marseille - France</a
              >
            </li>
            <li>
              <a href="mailto:contact@jacquart.com?subject=Information" class="text-gray-600 hover:text-green-600 flex items-center justify-center mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" /></svg
                >contact@jacquart.com</a
              >
            </li>
            <li>
              <a href="tel:+33320289428" class="text-gray-600 hover:text-green-600 flex items-center justify-center mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  /></svg
                >Tel: +33 (0)3.20.28.94.28</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="text-center p-4" style="background-color: rgba(0, 0, 0, 0.2)">© {{ currentYear }} - Jacquart & Fils</div>
  </footer>
</template>
<script>
// import axios from "axios";
export default {
  name: "FormNewsletter",

  data() {
    return {
      email: "",
      currentYear: new Date().getFullYear(),
    };
  },
  // methods: {
  //   async submitForm() {
  //     const mailFormat = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/;
  //     if (this.email.match(mailFormat)) {
  //       axios
  //         .post(`${process.env.VUE_APP_STRAPI_API_URL}/api/newsletters`, {
  //           data: { email: this.email },
  //         })
  //         .then((response) => {
  //           if (response.status == 200) {
  //             alert(this.$t("You are subscribed to the newsletter"));
  //             this.email = "";
  //             return true;
  //           }
  //           console.log(response.status);
  //         })
  //         .catch((error) => {
  //           if (error.response.status == 400) {
  //             if (
  //               error.response.data.error.message ==
  //               "This attribute must be unique"
  //             )
  //               alert(
  //                 this.$t("You have already registered for the newsletter")
  //               );
  //           } else {
  //             console.log(error.message);
  //           }
  //           return false;
  //         });
  //       return true;
  //     } else if (this.email == "") {
  //       alert(this.$t("Please enter an email before clicking the button"));
  //       return false;
  //     } else {
  //       alert(this.$t("Please enter a valid email"));
  //       return false;
  //     }
  //   },
  // },
};
</script>

<style></style>
