export default {
  "Search for you next machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouvez votre prochaine machine"])},
  "CONTACT US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTACTEZ-NOUS"])},
  "Firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom*"])},
  "Lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom*"])},
  "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email*"])},
  "Pays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays*"])},
  "Adresse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse*"])},
  "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description*"])},
  "Send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
  "Buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achat"])},
  "Sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vente"])},
  "Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Histoire"])},
  "Enter an adress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez une adresse"])},
  "Enter a country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez un pays"])},
  "Opportunity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opportunité"])},
  "InputCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise*"])},
  "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche"])},
  "Search...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherchez..."])},
  "Subscribe to this newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnez-vous à notre newsletter"])},
  "You are subscribed to the newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre abonnement à la lettre d'information a été pris en compte"])},
  "You have already registered for the newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes déjà inscrit à la newsletter"])},
  "Please enter an email before clicking the button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer un email avant de cliquer sur le bouton"])},
  "Please enter a valid email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer un email valide"])},
  "FIND YOUR MACHINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TROUVEZ UNE MACHINE"])},
  "Keyword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot-clé"])},
  "Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secteur"])},
  "Choose a sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir un secteur"])},
  "SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CHERCHER"])},
  "SELL YOUR MACHINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VENDRE VOTRE MACHINE"])},
  "Model Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèle"])},
  "Brand Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marque"])},
  "Year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Année"])},
  "InputYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Année"])},
  "SELL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VENDRE"])},
  "Subscribe to the newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnez-vous à notre newsletter"])},
  "Email address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse email"])},
  "Subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'abonner"])},
  "Aucun résultat trouvé": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun résultat trouvé"])},
  "After (Year)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À partir de"])},
  "FILTERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FILTRES"])},
  "Brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marque"])},
  "inputBrand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marque"])},
  "Category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
  "Choose a category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir une catégorie"])},
  "Choose a brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir une marque"])},
  "Specs :": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specs :"])},
  "Need more informations ?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besoin de plus d'informations ?"])},
  "Feel free to ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N'hésitez pas à demander"])},
  "Type : ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type : "])},
  "Ref :": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ref :"])},
  "Brand :": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marque :"])},
  "Year :": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Année :"])},
  "MACHINES SELECTED FOR YOU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MACHINES SÉLECTIONNÉES POUR VOUS"])},
  "YOU WANT TO SELL YOUR MACHINE ?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VOUS VOULEZ VENDRE VOTRE MACHINE ?"])},
  "We may be interested, please give us some informations.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous pouvons être intéressés, merci de nous donner quelques informations."])},
  "About your machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre machine"])},
  "Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèle"])},
  "inputModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèle"])},
  "Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])},
  "InputPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])},
  "About you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous"])},
  "Learn about our history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvrez notre histoire"])},
  "TRADE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMMERCE"])},
  "Needless to say, this is buying and selling. In the context of our operations we have to buy entire factories or lots of equipment in advance.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Inutile de dire qu'il s'agit d'achat et de vente. Dans le cadre de nos opérations, nous devons acheter des usines entières ou des lots d'équipements à l'avance."])},
  "This is a risk that we do not hesitate to take, in order to have a permanent stock on hand to offer to possible buyers.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C'est un risque que nous n'hésitons pas à prendre, afin de disposer d'un stock permanent à proposer aux éventuels acquéreurs."])},
  "BROKERAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COURTAGE"])},
  "In this case we are concerned with selling in your best interests, the equipment which you no longer need, in return for a commission fixed in advance.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans ce cas, il s'agit de vendre au mieux de vos intérêts, le matériel dont vous n'avez plus besoin, moyennant une commission fixée à l'avance."])},
  "For this, we regularly publish (more than 35000 copies) a bulletin listing all the equipment available for sale, and distribute it world-wide, based on a file which is updated daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour cela, nous publions régulièrement (plus de 35000 exemplaires) un bulletin répertoriant tous les équipements disponibles à la vente, et le diffusons dans le monde entier, sur la base d'un fichier mis à jour quotidiennement"])},
  "APPRAISALS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ÉVALUATIONS"])},
  "Essential as much for the buyer as the seller. We insist on carrying this out in absolute confidentiality for both parties concerned.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indispensable autant pour l'acheteur que pour le vendeur. Nous tenons à ce que cela se fasse dans la plus stricte confidentialité pour les deux parties concernées."])},
  "Our knowledge of the market and our experience in the textile field allow us to take everything into account when making an appraisal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre connaissance du marché et notre expérience dans le domaine textile nous permettent de tout prendre en compte lors d'une expertise"])},
  "100 years of experience at your service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100 ans d'expérience à votre service"])},
  "JACQUART company, already handed down from father to son for four generations, is justly proud of its past. Their experience as mill owners gives them the necessary expertise to act as highly qualified intermediaries between companies wishing to sell some individual machines or even their entire factory, and those who wish to buy second-hand textile equipment. Their competence is well-known and recognised by all professionals in the textile industry (mill owners, weavers, dyers, finishers) and those in sales (trade unions, auctioneers, financial institutions...).": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'entreprise JACQUART, déjà transmise de père en fils depuis quatre générations, est fière de son passé. Leur expérience en tant que propriétaires de moulins leur confère le savoir-faire nécessaire pour agir en tant qu'intermédiaires hautement qualifiés entre les entreprises souhaitant vendre des machines individuelles ou même leur totalité. usine, et ceux qui souhaitent acheter du matériel textile d'occasion, leur compétence est connue et reconnue par tous les professionnels de l'industrie textile (mouliniers, tisserands, teinturiers, ennoblisseurs) et de la vente (syndicats, commissaires-priseurs, financiers)."])},
  "Needless to say, this is buying and selling. In the context of our operations we have to buy entire factories or lots of equipment in advance. This is a risk that we do not hesitate to take, in order to have a permanent stock on hand to offer to possible buyers.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inutile de dire que c'est de l'achat et de la vente. Dans le cadre de nos opérations, nous devons acheter à l'avance des usines entières ou des lots de matériel. C'est un risque que nous n'hésitons pas à prendre, afin d'avoir un stock permanent de main à offrir aux éventuels acquéreurs."])},
  "In this case we are concerned with selling in your best interests, the equipment which you no longer need, in return for a commission fixed in advance. For this, we regularly publish (more than 35000 copies) a bulletin listing all the equipment available for sale, and distribute it world-wide, based on a file which is updated daily.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans ce cas nous nous soucions de vendre au mieux de vos intérêts, le matériel dont vous n'avez plus besoin, moyennant une commission fixée à l'avance. Pour cela, nous publions régulièrement (plus de 35000 exemplaires) un bulletin répertoriant l'ensemble du matériel disponible à vendre, et le diffuser dans le monde entier, sur la base d'un fichier mis à jour quotidiennement."])},
  "Essential as much for the buyer as the seller. We insist on carrying this out in absolute confidentiality for both parties concerned. Our knowledge of the market and our experience in the textile field allow us to take everything into account when making an appraisal.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indispensable autant pour l'acheteur que pour le vendeur. Nous tenons à ce que cela se fasse en toute confidentialité pour les deux parties concernées. Notre connaissance du marché et notre expérience dans le domaine textile nous permettent de tout prendre en compte lors de l'expertise."])},
  "Purchases - Sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achats - Ventes"])},
  "Our team is always ready to inspect equipment for sale on site, wherever its location. Visitors from all over the world take an interest in our second-hand equipment. Our aims in serving our clients are many : Canvassing, going to see equipment to be sold on site, wherever its location, making appraisals, replying to demands, meeting potential buyers…then supervising dimdantling and reassemble of equipment, transport, customs formalities etc... This we do with great passion and always with the essential philosophy of selling in mind : of serving our customers to the maximum.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre équipe est toujours prête à inspecter le matériel à vendre sur place, quelle que soit sa localisation. Des visiteurs du monde entier s'intéressent à notre matériel d'occasion. Nos objectifs au service de nos clients sont multiples : Démarchage, aller voir le matériel pour être vendu sur place, quelle que soit sa localisation, faire expertises, répondre aux demandes, rencontrer les acquéreurs potentiels… puis superviser le démontage et le remontage du matériel, le transport, les formalités douanières etc… Nous le faisons avec une grande passion et toujours avec la philosophie essentielle de la vente en tête : servir nos clients au maximum."])},
  "Implantation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Implantation"])},
  "Subscribe to our newsletter and receive new offers from our machine catalog. You can activate notifications on a specific category.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscrivez-vous à notre newsletter et recevez les nouvelles offres de notre catalogue de machines."])},
  "STAY TUNED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RESTEZ INFORMÉ"])},
  "Loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement..."])},
  "ITALY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ITALIE"])},
  "Make your choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faites votre choix"])},
  "Enter a machine model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez le modèle de la machine"])},
  "Enter a machine brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez la marque de la machine"])},
  "Enter a machine description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez une description de la machine"])},
  "Enter the year of manufacture of the machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entez l'année de fabrication de la machine"])},
  "Year of manufacture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Année de fabrication"])},
  "Enter a machine price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez le prix de la machine"])},
  "Enter a firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre prénom"])},
  "Enter a lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre nom"])},
  "Enter a email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre email"])},
  "Form send successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaire envoyé avec succès"])},
  "Something went wrong, please try again later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenu, veuillez réessayer plus tard"])},
  "Enter a valid email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez un email valide"])},
  "Enter a description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez une descirption"])},
  "* Required fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Champs obligatoire"])},
  "Opportunity page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page opportunité"])},
  "description_not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description non disponible"])},
  "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la société"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse postale"])},
  "Activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
  "subActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machines"])},
  "Subcategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous-catégorie"])},
  "interested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ça m'intéresse"])},
  "auction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enchères"])},
  "no-pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas d'image disponible"])},
  "all-fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez renseigner tous les champs"])},
  "send-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y a eu une erreur et votre demande n'a pas été enregistrée!"])},
  "send-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre demande a été envoyée avec succès!"])},
  "opportunities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opportunités"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
  "You-have-selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez sélectionné: "])},
  "please-fill-all-fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez remplir tous les champs obligatoires"])},
  "File": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier"])},
  "Select-activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez vos activités"])}
}