<template>
  <div class="flex justify-center items-center px-5 py-5">
    <router-link :to="{ name: 'Buy' }">
      <button
        type="submit"
        class="bg-transparent border-2 border-green-700 px-2 py-2 rounded hover:bg-hovercolor active:bg-green-600 focus:outline-none hover:text-white"
      >
        {{ $t("Search for you next machine") }}
      </button>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "ButtonSearch",
};
</script>
