<template>
  <div class="flex justify-center firstHeader rounded" @click="checkParentModal">
    <div class="flex md:flex md:w-8/12">
      <header class="bg-second shadow-md overflow-hidden flex justify-center p-6 mx-auto">
        <div class="bg-color rounded-xl shadow-md overflow-hidden flex justify-center items-center md:flex md:w-8/12 border-4 border-white">
          <div class="machine col-sm-12 col-md-10 col-lg-8 d-flex flex-column justify-content-center align-items-center">
            <div class="flex flex-row justify-between p-1">
              <img src="@/assets/rivet.png" alt="" class="" />
              <img src="@/assets/rivet.png" alt="" class="" />
            </div>
            <div class="flex">
              <img src="@/assets/logo.png" alt="logo jacquart" class="" />
            </div>
            <div class="bg-graen-600 flex justify-around mt-4 items-center">
              <img src="@/assets/download.svg" alt="CE-image" class="w-20 pb-5" />

              <label for="search-value" class="text-white pr-10 md:text-sm sm:text-xs flex">MADE IN FRANCE</label>
            </div>
            <div class="p-5 space-y-5">
              <div class="rounded-xl border-2 shadow-md">
                <div class="py-5 mb-6">
                  <div class="md:shrink-0 pl-5 pt-2">
                    <h1 class="text-2xl flex justify-start items-center text-white">
                      {{ $t("FIND YOUR MACHINE") }}
                    </h1>
                  </div>
                  <!-- formulaire d'achat -->

                  <div action="/buy" method="get">
                    <div class="pl-5 justify-around md:flex grid grid-flow-col">
                      <div class="pt-5 grid grid-flow-row gap-3 w-full relative">
                        <input
                          v-model="search"
                          name="search"
                          type="text"
                          :placeholder="$t('Keyword')"
                          class="form-control block md:w-full px-3 py-1.5 sm:w-full text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
                        />
                        <!-- START activity FILTER -->
                        <div class="px-1 relative" id="modal">
                          <div class="">
                            <div class="my-2 bg-white p-1 flex border border-gray-200 rounded">
                              <input :placeholder="$t('Activity')" v-model="inputActivity" @focus="getActivity" @input="getActivity" class="p-1 px-2 appearance-none outline-none w-full text-gray-800" />
                              <div>
                                <button
                                  @click="
                                    inputActivity = '';
                                    inputCode = '';
                                    inputSubCategory = '';
                                    inputSubCode = '';
                                    isActivityOpen = false;
                                    isSubCategoryOpen = false;
                                  "
                                  class="cursor-pointer w-6 h-full flex items-center text-gray-400 outline-none focus:outline-none"
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x w-4 h-4">
                                    <line x1="18" y1="6" x2="6" y2="18"></line>
                                    <line x1="6" y1="6" x2="18" y2="18"></line>
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div v-if="isActivityOpen" class="shadow top-100 z-10 w-full lef-0 rounded max-h-select overflow-y-auto max-h-96 absolute">
                            <div class="flex flex-col w-full">
                              <div
                                class="cursor-pointer w-full border-gray-100 rounded-t border-b hover:bg-teal-100"
                                style=""
                                v-for="(activity, index) in activitys"
                                :key="'activity' + index"
                                @click="
                                  setInputActivity(activity.attributes);
                                  inputCode = deleteNumber(activity.attributes.code.trim());
                                  isActivityOpen = false;
                                "
                              >
                                <div class="flex w-full items-center hover:bg-gray-100 p-2 pl-2 border-transparent bg-white border-l-2 relative hover:bg-teal-600 hover:text-teal-100 hover:border-teal-600">
                                  <div class="w-full items-center flex">
                                    <div class="mx-2 leading-6">{{ $i18n.locale == "fr" ? activity.attributes.descFR : activity.attributes.descEN.trim() ? activity.attributes.descEN : activity.attributes.descFR }}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- END activity FILTER -->

                        <!-- START SUB CATEGORY FILTER -->
                        <div class="px-1" id="modal">
                          <div class="">
                            <div class="my-2 bg-white p-1 flex border border-gray-200 rounded" :style="inputActivity ? 'opacity: 1' : 'opacity: 0.4'">
                              <input :disabled="inputActivity ? false : true" :placeholder="$t('subActivity')" v-model="inputSubCategory" @focus="getSubCategory" @input="getSubCategory" class="p-1 px-2 appearance-none outline-none w-full text-gray-800" />
                              <div>
                                <button
                                  @click="
                                    inputSubCategory = '';
                                    inputSubCode = '';
                                    isSubCategoryOpen = false;
                                  "
                                  class="cursor-pointer w-6 h-full flex items-center text-gray-400 outline-none focus:outline-none"
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x w-4 h-4">
                                    <line x1="18" y1="6" x2="6" y2="18"></line>
                                    <line x1="6" y1="6" x2="18" y2="18"></line>
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div v-if="subCategories.length >= 1 && isSubCategoryOpen" class="shadow top-100 z-10 w-full lef-0 rounded max-h-select overflow-y-auto max-h-96 absolute">
                            <div class="flex flex-col w-full">
                              <div
                                class="cursor-pointer w-full border-gray-100 rounded-t border-b hover:bg-teal-100"
                                style=""
                                v-for="(subCategory, index) in subCategories"
                                :key="'subCategory' + index"
                                @click="
                                  setInputSubCategory(subCategory.attributes);
                                  inputSubCode = subCategory.attributes.code.trim();
                                  isSubCategoryOpen = false;
                                "
                              >
                                <div class="flex w-full items-center hover:bg-gray-100 p-2 pl-2 border-transparent bg-white border-l-2 relative hover:bg-teal-600 hover:text-teal-100 hover:border-teal-600">
                                  <div class="w-full items-center flex">
                                    <div class="mx-2 leading-6">{{ $i18n.locale == "fr" ? subCategory.attributes.descFR : subCategory.attributes.descEN.trim() ? subCategory.attributes.descEN : subCategory.attributes.descFR }}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- END SUB CATEGORY FILTER -->

                        <!-- START BRAND FILTER -->
                        <!-- <div class="px-1">
                          <div class="">
                            <div class="my-2 bg-white p-1 flex border border-gray-200 rounded">
                              <input name="brand" type="text" :placeholder="$t('Brand')" v-model="inputBrand" @input="getBrands" class="p-1 px-2 appearance-none outline-none w-full text-gray-800" />
                              <div>
                                <button @click="inputBrand = ''" class="cursor-pointer w-6 h-full flex items-center text-gray-400 outline-none focus:outline-none">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x w-4 h-4">
                                    <line x1="18" y1="6" x2="6" y2="18"></line>
                                    <line x1="6" y1="6" x2="18" y2="18"></line>
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div v-if="inputBrand.length >= 1 && isBrandOpen" class="shadow top-100 z-10 w-full lef-0 rounded max-h-select overflow-y-auto max-h-96 absolute">
                            <div class="flex flex-col w-full">
                              <div
                                class="cursor-pointer w-full border-gray-100 rounded-t border-b hover:bg-teal-100"
                                style=""
                                v-for="(brand, index) in brands"
                                :key="'brand' + index"
                                @click="
                                  inputBrand = brand.attributes.Brand.trim();
                                  isBrandOpen = false;
                                "
                              >
                                <div class="flex w-full items-center hover:bg-gray-100 p-2 pl-2 border-transparent bg-white border-l-2 relative hover:bg-teal-600 hover:text-teal-100 hover:border-teal-600">
                                  <div class="w-full items-center flex">
                                    <div class="mx-2 leading-6">{{ brand.attributes.Brand }}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> -->
                        <!-- END BRAND FILTER -->
                      </div>

                      <div class="pb-5 px-5 py-5">
                        <button type="submit" class="rounded-sm flex justify-center justify-items-center" @click="redirect()">
                          <img src="@/assets/green_button.png" alt="logo jacquart" class="justify-items-center grid grid-flow-row" />
                        </button>
                        <p class="flex justify-center text-center text-white">
                          {{ $t("SEARCH") }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="rounded-xl border-2 shadow-md overflow-hidden">
                <div class="py-5 mb-8">
                  <div class="md:shrink-0 pl-5 pt-2">
                    <h1 class="text-2xl flex justify-start items-center text-white">
                      {{ $t("SELL YOUR MACHINE") }}
                    </h1>
                  </div>
                  <!-- formulaire de vente -->
                  <form action="/sell" method="get">
                    <div class="pl-5 justify-around md:flex grid grid-flow-col">
                      <div class="pt-5 grid grid-flow-row gap-3 w-full h-32 relative">
                        <input
                          v-model="model"
                          name="model"
                          type="text"
                          :placeholder="$t('Model Name')"
                          class="form-control block md:w-full px-3 py-1.5 sm:w-full text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
                        />
                        <input
                          v-model="brand"
                          name="brand"
                          type="text"
                          :placeholder="$t('Brand Name')"
                          class="form-control block md:w-full px-3 py-1.5 sm:w-full text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
                        />
                        <input
                          v-model.number="year"
                          name="year"
                          type="number"
                          :placeholder="$t('Year')"
                          class="form-control block md:w-full px-3 py-1.5 sm:w-full text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green-600 focus:outline-none"
                        />
                      </div>

                      <div class="pb-5 px-5 py-5">
                        <button type="submit" class="rounded-sm flex justify-center justify-items-center">
                          <img src="@/assets/button-secondary.png" alt="logo jacquart" class="justify-items-center grid grid-flow-row" />
                        </button>
                        <p class="flex justify-center text-center text-1xl text-white">
                          {{ $t("SELL") }}
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="flex flex-row justify-between p-1">
              <img src="@/assets/rivet.png" alt="" class="" />
              <img src="@/assets/rivet.png" alt="" class="" />
            </div>
          </div>
        </div>
      </header>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
export default {
  name: "Header",
  data() {
    return {
      search: "",
      selectedSector: "",
      //FormSell
      model: "",
      brand: "",
      year: "",
      //
      sectors: [],
      brands: [],
      activitys: [],
      subCategories: [],
      inputActivity: "",
      inputSubCategory: "",
      inputCode: "",
      inputSubCode: "",
      inputBrand: "",
      isActivityOpen: false,
      isSubCategoryOpen: false,
      isSectorOpen: false,
      isBrandOpen: false,
    };
  },
  computed: {},
  mounted() {
    // this.getSectors();
    // this.getBrands();
  },
  methods: {
    redirect() {
      location.replace(`/buy?search=${this.search}&activity=${this.inputActivity}&code=${this.inputCode}&subCategory=${this.inputSubCategory}&subCode=${this.inputSubCode}&brand=${this.inputBrand}`);
    },
    deleteNumber(string) {
      return string.replace(/[0-9]/g, "");
    },
    async getActivity() {
      this.isSubCategoryOpen = false;
      this.isBrandOpen = false;
      let query = qs.stringify({
        sort: this.$i18n.locale == "fr" ? "descFR:asc" : "descEN:asc",
        fields: ["descFR", "descEN", "code"],
        filters: this.$i18n.locale == "fr" ? { descFR: { $contains: this.inputActivity.trim() }, code: { $contains: "00" } } : { descEN: { $contains: this.inputActivity.trim() }, code: { $contains: "00" } },
      });
      try {
        const response = await axios.get(`${process.env.VUE_APP_STRAPI_API_URL}/api/bible-materials?${query}`);
        const uniqueActivity = response.data.data.filter((value, index, self) => {
          return self.findIndex((obj) => obj.attributes.descFR === value.attributes.descFR) === index;
        });
        this.activitys = uniqueActivity;
        this.isActivityOpen = true;
      } catch (error) {
        this.error = error;
      }
    },

    async getSubCategory() {
      this.isSubCategoryOpen = true;
      this.isActivityOpen = false;
      this.isBrandOpen = false;
      let query = qs.stringify({
        sort: this.$i18n.locale == "fr" ? "descFR:asc" : "descEN:asc",
        fields: ["descFR", "descEN", "code"],
        filters: { descFR: { $contains: this.inputSubCategory.trim() }, code: { $startsWith: this.inputCode } },
      });
      try {
        const response = await axios.get(`${process.env.VUE_APP_STRAPI_API_URL}/api/bible-materials?${query}`);
        const uniqueSubCategory = response.data.data.filter((value, index, self) => {
          return self.findIndex((obj) => obj.attributes.descFR === value.attributes.descFR) === index;
        });
        this.subCategories = uniqueSubCategory;
      } catch (error) {
        this.error = error;
      }
    },

    setInputActivity(value) {
      this.inputActivity = this.$i18n.locale == "fr" ? value.descFR.trim() : value.descEN.trim() ? value.descEN.trim() : value.descFR.trim();
    },
    setInputSubCategory(value) {
      this.inputSubCategory = this.$i18n.locale == "fr" ? value.descFR.trim() : value.descEN.trim() ? value.descEN.trim() : value.descFR.trim();
    },

    async getBrands() {
      this.isActivityOpen = false;
      this.isSubCategoryOpen = false;
      this.isBrandOpen = true;
      let query = qs.stringify({
        fields: "Brand",
        filters: {
          Brand: { $contains: this.inputBrand.trim() },
        },
      });

      try {
        const response = await axios.get(`${process.env.VUE_APP_STRAPI_API_URL}/api/machine-fiches?${query}`);
        const uniqueBrand = response.data.data.filter((value, index, self) => {
          return self.findIndex((obj) => obj.attributes.Brand === value.attributes.Brand) === index;
        });
        this.brands = uniqueBrand;
      } catch (error) {
        this.error = error;
        console.log(error.response);
      }
    },
    checkParentModal(event) {
      let parent = event.target.parentElement;
      let found = false;
      while (parent) {
        if (parent.id === "modal") {
          found = true;
        }
        parent = parent.parentElement;
      }
      if (!found) {
        (this.isActivityOpen = false), (this.isBrandOpen = false), (this.isSubCategoryOpen = false);
      }
    },
  },
};
</script>

<style>
.firstHeader {
  background: url("../assets/background.jpeg") center center / cover;
}
</style>
